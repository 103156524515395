import { SHAREDFOLDERS,PERSONALFOLDERS,FAVORITEFOLDERS,SEARCH,EMPLOYEES,CLIENTS,MESSAGES,COOKIE_CONFIRM } from './constants';
import { SITENAMEALIAS } from '../init';

/*** REDUCER DEFINATION FOR SEARCH IN HEADER SECTION ***/
export const searchReducer = (state = {}, action) => {

    switch (action.type) {
        case SEARCH:
            return { searchObj: { ...state, searchQuery : action.searchText } };
        default:
            return state;
    }
}

/*** REDUCER DEFINATION FOR EMPLOYEE LIST ***/
export const employeeListReducer = (state = {}, action) => {

    switch (action.type) {
        case EMPLOYEES:
            return  { ...state, employeesList : action.employees } ;
        default:
            return state;
    }
}

/*** REDUCER DEFINATION FOR CLIENT LIST ***/
export const clientListReducer = (state = {}, action) => {

    switch (action.type) {
        case CLIENTS:
            return { ...state, clientsList : action.clients } ;
        default:
            return state;
    }
}

/*** REDUCER DEFINATION FOR PERSONAL FOLDERS ***/
export const personalFoldersReducer = (state = [], action) => {
    switch (action.type) {
        case PERSONALFOLDERS:
            return { ...state, list : action.list } ;
        default:
            return state;
    }
}

/*** REDUCER DEFINATION FOR SHARED FOLDERS ***/
export const sharedFoldersReducer = (state = [], action) => {

    switch (action.type) {
        case SHAREDFOLDERS:
            return { ...state, list : action.list } ;
        default:
            return state;
    }
}

/*** REDUCER DEFINATION FOR FAVORITE FOLDERS ***/
export const favoriteFoldersReducer = (state = [], action) => {

    switch (action.type) {
        case FAVORITEFOLDERS:
            return { ...state, list : action.list } ;
        default:
            return state;
    }
}

/*** REDUCER DEFINATION FOR MESSAGE LIST ***/
export const messageListReducer = (state = [], action) => {

  switch (action.type) {
      case MESSAGES:
          return { ...state, messageList : action.list } ;
      default:
          return state;
  }
}

/*** REDUCER DEFINATION FOR MESSAGE LIST ***/
export const cookieConfirmReducer = (state = false, action) => {
  console.log(state, action)
  switch (action.type) {
      case COOKIE_CONFIRM:
          return { ...state, cookieConfirm : action.state } ;
      default:
        console.log(localStorage.getItem(SITENAMEALIAS + '_cookie_confirm') == 'true')
          return localStorage.getItem(SITENAMEALIAS + '_cookie_confirm') == 'true';
  }
}