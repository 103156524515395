import React, { Fragment } from 'react';
import { Link,NavLink } from 'react-router-dom';
import { SITENAMEALIAS, SITENAME } from '../utils/init';
import { connect } from 'react-redux';
import { setCookieConfirm } from "../utils/redux/action";


 class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

        
    }


    

   


    render() {
        return (
            <Fragment>
               <footer className="app-footer log_foot">
                    <div>
                        <Link to="/dashboard">Smart Docustore Ltd</Link>
                        <span>© 2021 .</span>
                    </div>
                    <div className="termbox">
                    <a href="https://smart-formation.smart-doc.co.uk/extra/terms.html" target="_blank">Term and Conditions</a>
                        <a href="https://smart-formation.smart-doc.co.uk/extra/privacy.html" target="_blank">GDPR Privacy Policy</a>
                    </div>
                </footer>

                { this.props.globalState.cookieConfirmReducer == false && <div className="cookie-wrapper">
                    <div className='cookie-container'>
                        <div className='flex-one'>
                            <h2>About cookies on this site</h2>
                            <p>In order to provide more relevant experience for you, we use cookies to enable some website functionality. In addition, other cookies may be used with your consent to analyze site usage, improve the user experience and provide many other site benefits.</p>
                        </div>
                        <div className='loging_buttom w-260'>
                            <button onClick={(event) => { this.props.setCookieConfirm(true) }}>Accept All</button>
                        </div>
                    </div>
                </div> }
            </Fragment>
        )
    }
    
}

const mapStateToProps = state => {
  return {
      globalState: state
  }
}

const mapDispatchToProps = dispatch => {
  return {
      setCookieConfirm: (boolean) => dispatch(setCookieConfirm(boolean)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)