import React, { Fragment } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import { SITENAMEALIAS } from '../utils/init';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { GetAllMessages, RemoveMessage, LoginUser } from '../utils/service'
import { showToast, showHttpError } from '../utils/library'
import { setMessageList } from "../utils/redux/action"
import { Modal } from 'react-bootstrap';


class BrowseMessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            selectedAlphabetOfUser: "",
            activePage: 1,
            pageNo: 1,
            noOfItemsPerPage: 50,
            totalCount: 0,
            messageList: [],
            isPasswordMatched: false,
            showConfirmPasswordModal: false,
            selectedPasswordForDeletion: [],
            searchQuery: '',
            selectedMessage: -1,
        };
        /***  BINDING FUNCTIONS  ***/
        this.selectUserNameAlphabet = this.selectUserNameAlphabet.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.getAllMessagesList = this.getAllMessagesList.bind(this)
        this.deleteMessage = this.deleteMessage.bind(this)
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this)
        this.propagateConfirmPasswordModal = this.propagateConfirmPasswordModal.bind(this)
        this.handleSelectMultiUser = this.handleSelectMultiUser.bind(this)
        this.handleInitiateMultipleDelete = this.handleInitiateMultipleDelete.bind(this)
        this.handleSearchUser = this.handleSearchUser.bind(this)

        /*** REFERENCE FOR RETRIEVING INPUT FIELDS DATA ***/
        this.passwordRef = React.createRef();
    }

    /*** FUNCTION DEFINATION FOR SELECTING ALPHABET OF CLIENTS NAME ***/
    selectUserNameAlphabet = (e) => {
        this.setState({ selectedAlphabetOfUser: e.target.value })
        setTimeout(function () {
            console.log(this.state.selectedAlphabetOfUser)
            this.getAllMessagesList()
        }.bind(this), 1000)
    }

    /*** FUNCTION DEFINATION FOR SELECTING PAGE FROM PAGINATION ***/
    handlePageChange = (page) => {
        this.setState({
            activePage: page,
            pageNo: page
        })
        setTimeout(() => {
            this.getAllMessagesList()
        }, 1000);
    }

    /*** FUNCTION DEFINATION TO GET MESSAGES LIST ****/
    getAllMessagesList = () => {
        let payload = {
            page_no: this.state.pageNo,
            page_size: this.state.noOfItemsPerPage,
        }
        if(this.state.selectedAlphabetOfUser != '') { 
          payload.startsWith = this.state.selectedAlphabetOfUser;
        } 
        if(this.state.searchQuery !== '') {
          payload.searchQuery = this.state.searchQuery;
        } 
        this.setState({ showLoader: true })
        GetAllMessages(payload).then(function (res) {
            this.setState({ showLoader: false })
            var response = res.data;
            if (response.errorResponse.errorStatusCode != 1000) {
                showToast('error', response.errorResponse.errorStatusType);
            } else {

                let allMessageList = response.response.data;
                let totalMessageCount = response.response.totalCount;
                let messagesList = allMessageList;
                // for (let i = 0; i < allMessageList.length; i++) {
                //     // if (allMessageList[i].user_role == 'EMPLOYEE') {
                //         console.log(this.state.selectedAlphabetOfUser)
                //         if (this.state.selectedAlphabetOfUser != '') {
                //             console.log('if')
                //             let firstCharcterOfName = allMessageList[i].name.charAt(0);
                //             let firstCharcterOfEmail = atob(allMessageList[i].email).charAt(0);

                //             if (firstCharcterOfEmail.toLowerCase() == this.state.selectedAlphabetOfUser || firstCharcterOfName.toLowerCase() == this.state.selectedAlphabetOfUser) {
                //                 messagesList.push(allMessageList[i])
                //             }
                //         } else if (this.state.searchQuery !== '') {
                //             console.log('else if')
                //             let firstName = allMessageList[i].name;
                //             let email = atob(allMessageList[i].email);
                //             if (firstName.toLowerCase().indexOf(this.state.searchQuery) !== -1 || email.toLowerCase().indexOf(this.state.searchQuery) !== -1) {
                //                 console.log(allMessageList[i])
                //                 messagesList.push(allMessageList[i])
                //             }
                //         } else {
                //             console.log('else')
                //             messagesList.push(allMessageList[i])
                //         }
                //     // }
                // }
                this.props.setMessageList(messagesList);
                this.setState({ messageList: this.props.globalState.messageListReducer.messageList, totalCount: totalMessageCount })
            }
        }.bind(this)).catch(function (err) {
            this.setState({ showLoader: false })
            showHttpError(err, this.props)
        }.bind(this))

    }

    /**** FUNCTION DEFINATION FOR DELETING CLIENT ****/
    deleteMessage = () => {
        let selectedPasswordForDeletion = this.state.selectedPasswordForDeletion
        // console.log(selectedPasswordForDeletion);return;
        for (let i = 0; i < selectedPasswordForDeletion.length; i++) {
            let payload = { _id: selectedPasswordForDeletion[i] }
            RemoveMessage(payload).then(function (res) {
                this.setState({ showLoader: false })
                var response = res.data;
                if (response.errorResponse.errorStatusCode != 1000) {
                    showToast('error', response.errorResponse.errorStatusType);
                } else {
                    showToast('success', 'Message deleted successfully');
                    this.getAllMessagesList();

                }
            }.bind(this)).catch(function (err) {
                this.setState({ showLoader: false })
                showHttpError(err, this.props)
            }.bind(this))
        }
    }

    /*** FUNCTION DEFINATION FOR HANDLING SUBMIT FOR CONFIRM WITH PASSWORD BEFORE DELETE ****/
    handleConfirmPassword = (e) => {
        e.preventDefault();

        let session = JSON.parse(atob(localStorage.getItem(SITENAMEALIAS + '_session')));

        //if(session.user_role == 'ADMIN'){
        let email = atob(session.user_email)
        /*  }else{
             var email = session.employee_email
         } */
        let payload = {
            user_email: email,
            user_password: this.passwordRef.current.value
        }
        this.setState({ showLoader: true })
        LoginUser(payload).then(function (res) {
            this.setState({ showLoader: false })
            var response = res.data;
            if (response.errorResponse.errorStatusCode != 1000) {
                showToast('error', response.errorResponse.errorStatusType);
            } else {
                this.setState({
                    showConfirmPasswordModal: false,
                })
                this.deleteMessage()
            }
        }.bind(this)).catch(function (err) {
            this.setState({ showLoader: false })
            showHttpError(err, this.props)
        }.bind(this))


    }

    /*** Function defination to propagate confirm password modal ***/
    propagateConfirmPasswordModal = (param) => {
        let arr = []
        arr.push(param)
        this.setState({ showConfirmPasswordModal: true, selectedPasswordForDeletion: arr })
    }
    /*** Function defination to select multiple user for deletion using check box ***/
    handleSelectMultiUser = (e) => {
        if(this.state.selectedPasswordForDeletion.length == 1) {
          let elem = document.getElementById(this.state.selectedPasswordForDeletion[0]);
          if(!elem.checked) {
            this.state.selectedPasswordForDeletion = [];
            this.setState({ selectedPasswordForDeletion: [] });
          }
        }
        let arr = this.state.selectedPasswordForDeletion;
        let id = e.target.getAttribute("data-id");

        if (e.target.checked) {
            arr.push(id)
        } else {
            var index = arr.indexOf(id);
            if (index > -1) {
                arr.splice(index, 1);
            }
        }
        console.log(arr)
        this.setState({ selectedPasswordForDeletion: arr })
    }

    /*** Function defination to initate multiple delte ***/
    handleInitiateMultipleDelete = () => {
        if (this.state.selectedPasswordForDeletion.length != 0) {
            this.setState({ showConfirmPasswordModal: true })
        }

    }

    /*** FUNCTION DEFINATION FOR SEARCH CLIENTS ****/
    handleSearchUser = (param) => {
        if (param != '') {
            if (param.length >= 3) {
                this.setState({ searchQuery: param, pageNo: 1,activePage:1 })
                setTimeout(function () {
                    console.log(this.state.searchQuery)
                    this.getAllMessagesList()
                }.bind(this), 1000)
            }
        } else {
            this.setState({ searchQuery: '', pageNo: 1,activePage:1 })
            setTimeout(function () {
                console.log(this.state.searchQuery)
                this.getAllMessagesList()
            }.bind(this), 1000)
        }
    }

    selectAllEntity = (e) => {
      let checkedValue = e.target.checked
      console.log(checkedValue)
      let elem = document.getElementsByClassName('desCheckBox');
      for (let i = 0; i < elem.length; i++) {
          if (checkedValue) {
              if (elem[i].checked === false) {
                  elem[i].click();
              }
          } else {
              elem[i].click();
          }
      }
  }

    render() {
        return (
            <Fragment>
                <Header />
                <div className="app-body">
                    <Sidebar />
                    <main className="main">
                        <div className="container-fluid">
                            <div id="ui-view">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card_cstm same_dv_table">
                                            <div className="card-header">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="lft-hdr"><span><i className="fas fa-users"></i></span>Browse Messages</div>
                                                    <div className="lft-hdr">
                                                        <input type="text" className="form-control" placeholder="Search Name or Email" onKeyUp={(e) => { this.handleSearchUser(e.target.value) }} />
                                                    </div>
                                                    <div className="addbutton">
                                                        <div className="serach_box_bylte">
                                                            <form>
                                                                <label>Search By Letter</label>

                                                                <select className=" form-control" onChange={this.selectUserNameAlphabet}>
                                                                    <option value="">ALL</option>
                                                                    <option value="a">A</option>
                                                                    <option value="b">B</option>
                                                                    <option value="c">C</option>
                                                                    <option value="d">D</option>
                                                                    <option value="e">E</option>
                                                                    <option value="f">F</option>
                                                                    <option value="g">G</option>
                                                                    <option value="h">H</option>
                                                                    <option value="i">I</option>
                                                                    <option value="j">J</option>
                                                                    <option value="k">K</option>
                                                                    <option value="l">L</option>
                                                                    <option value="m">M</option>
                                                                    <option value="n">N</option>
                                                                    <option value="o">O</option>
                                                                    <option value="p">P</option>
                                                                    <option value="q">Q</option>
                                                                    <option value="r">R</option>
                                                                    <option value="s">S</option>
                                                                    <option value="t">T</option>
                                                                    <option value="u">U</option>
                                                                    <option value="v">V</option>
                                                                    <option value="w">W</option>
                                                                    <option value="x">X</option>
                                                                    <option value="y">Y</option>
                                                                    <option value="z">Z</option>
                                                                </select>
                                                            </form>
                                                        </div>
                                                        { this.state.selectedPasswordForDeletion.length > 0 && <button type="submit" className="deleteclient" onClick={this.handleInitiateMultipleDelete}><i className="fas fa-user-minus"></i>Delete Selected Message</button> }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body custom_card_body_browesclient">
                                                <div className="dash_lft_t" style={{ overflowX: "auto" }}>
                                                    <table className="table table-striped table-bordered dt-responsive text-center">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" id="check_all" onClick={(event) => { this.selectAllEntity(event) }} />
                                                                        <label className="custom-control-label" htmlFor="check_all"></label>
                                                                    </div>
                                                                </th>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Subject</th>
                                                                <th>Sent on</th>
                                                                <th>Manage</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.messageList.map((list, index) =>
                                                                <tr key={list._id}>
                                                                    <td>
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" className="custom-control-input checkbox desCheckBox" onClick={(event) => { this.handleSelectMultiUser(event) }} data-id={list._id} id={list._id} />
                                                                            <label className="custom-control-label" htmlFor={list._id}></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>{list.name}</td>
                                                                    <td>{atob(list.email)}</td>
                                                                    {/* <td>{list.message.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")}</td> */}
                                                                    <td>{list.subject}</td>
                                                                    <td>
                                                                        <Moment format="YYYY/MM/DD" date={list.created} />
                                                                    </td>
                                                                    <td>
                                                                        <div className="ac_bot d-flex justify-content-center"> 
                                                                            <a href="javascript:void(0)" className="btn btn-light view_dlt" onClick={() => { this.setState({ selectedMessage: index }) }}><i className="fas fa-eye"></i></a>
                                                                            <a href="javascript:void(0)" className="btn btn-light view_dlt" onClick={() => { this.propagateConfirmPasswordModal(list._id) }}><i className="fas fa-user-minus"></i></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <div className="row">
                                                        <div className="col-md-8"></div>
                                                        <div className="col-md-4 float-right">
                                                            <Pagination
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={this.state.noOfItemsPerPage}
                                                                totalItemsCount={this.state.totalCount}
                                                                pageRangeDisplayed={5}
                                                                onChange={this.handlePageChange.bind(this)}
                                                                itemClass="page-item"
                                                                linkClass="page-link"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
                <Modal
                    show={this.state.showConfirmPasswordModal}
                    onHide={() => { this.setState({ showConfirmPasswordModal: false }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="importmodal_content">
                            <div className="importmodal_contentfirst">

                                <p>Please provide your password before procedding </p>
                                <form className="form-inline" onSubmit={this.handleConfirmPassword}>
                                    <div className="form-group mx-sm-3 mb-2">
                                        <label for="inputPassword2" className="sr-only">Password</label>
                                        <input type="password" className="form-control" id="confirmPassword" placeholder="Password" ref={this.passwordRef} />
                                    </div>
                                    <button type="submit" className="btn btn-primary mb-2">Confirm identity</button>
                                </form>
                            </div>

                        </div>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={this.state.selectedMessage > -1}
                    onHide={() => { this.setState({ selectedMessage: -1 }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="importmodal_content">
                            <div className="importmodal_contentfirst">

                                { this.state.selectedMessage > -1 && <div dangerouslySetInnerHTML={{__html: this.state.messageList[this.state.selectedMessage].message}}></div> }
                                {/* <form className="form-inline" onSubmit={this.handleConfirmPassword}>
                                    <div className="form-group mx-sm-3 mb-2">
                                        <label for="inputPassword2" className="sr-only">Password</label>
                                        <input type="password" className="form-control" id="confirmPassword" placeholder="Password" ref={this.passwordRef} />
                                    </div>
                                    <button type="submit" className="btn btn-primary mb-2">Confirm identity</button>
                                </form> */}
                            </div>

                        </div>
                    </Modal.Body>

                </Modal>

                <Loader show={this.state.showLoader} />
            </Fragment>

        )
    }

    componentDidMount() {
        /*** FUNCTION CALL FOR RETRIEVING ALL MESSAGES LIST ***/
        this.getAllMessagesList()

    }


}

const mapStateToProps = state => {
    return {
        globalState: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMessageList: (array) => dispatch(setMessageList(array)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowseMessages)
