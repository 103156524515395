export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const SEARCH = 'SEARCH';
export const EMPLOYEES = 'EMPLOYEES';
export const CLIENTS = 'CLIENTS';
export const PERSONALFOLDERS = 'PERSONALFOLDERS';
export const SHAREDFOLDERS = 'SHAREDFOLDERS';
export const FAVORITEFOLDERS = 'FAVORITEFOLDERS';
export const MESSAGES = 'MESSAGES';
export const COOKIE_CONFIRM = 'COOKIE_CONFIRM';
